import React from "react";
import styled from "styled-components";
import FaqData from './faqData'
import { Collapse } from 'antd';
import './index.css'

const { Panel } = Collapse;

const FaqBox = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: 1rem;
`;


function MobileFaq() {
  return (
    <>
      <div id='FAQ' className="mobileFaq">
        <FaqBox>
          <div className="mobile_title">
            FAQ
          </div>
          <div className="contain">
            <div className="faq_list">
              <Collapse
                accordion
                expandIconPosition={'right'}>
                {
                  FaqData.map((item, index) => {
                    return (
                      <Panel header={item.q} key={index}>
                        <p className="faqFont">{item.w}</p>
                      </Panel>
                    )
                  })
                }
              </Collapse>
            </div>
          </div>
        </FaqBox>
      </div>
    </>
  )
}


export default MobileFaq