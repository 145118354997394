import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './assets/fonts/fonts.css';
import './App.css';
import { isMobile } from './libs/userAgent';
import HomePage from './routes/home';
import MobilePage from './routes/mobile';
import { useEffect } from 'react';
// import PublicSaleCountDown from './PublicSale';
import UpComing from './upcoming';
import AirDrop from './airdrop';
import MobileMint from './mobileMint';

function App() {
  useEffect(() => {
  })




  return (
    <Router>
      <Routes>
        <Route path="/" element={<Redirect />}>
          <Route path="/home" element={<HomePage />} />
        </Route>
        <Route path="/mobile" element={<MobilePage />} />
        <Route path="/upcomings" element={<UpComing />} />
        <Route path="/airdrop" element={<AirDrop />} />
        <Route path='/mobilemint' element={<MobileMint />}></Route>
      </Routes>
    </Router>
  );
}

export default App;

const Redirect = (): JSX.Element => !isMobile() ? <HomePage /> : <MobilePage />
