/* eslint-disable @typescript-eslint/no-unused-expressions */
import Web3 from 'web3';
import * as ethers from 'ethers';
import QRCodeModal from "@walletconnect/qrcode-modal";
import WalletConnect from '@walletconnect/client';
import { devAbi, devContractAddress, proAbi, proContractAddress } from './abi';
import { apiGetAccountNonce, apiGetGasPrices } from '../helpers/api';
import { convertAmountToRawNumber, convertStringToHex } from '../helpers/bignumber';
// import { whiteListToken } from './whiteList';
import { sanitizeHex } from '../helpers/utilities';

type networkChangeCallback = () => void;

type accountChangeCallback = () => void;

const bridge = "https://bridge.walletconnect.org";
declare var window: any
export class NxWeb3 {
  private static _ins: NxWeb3;
  WalletConnector: WalletConnect | any;
  ChainId: any;
  static get instance(): NxWeb3 {
    return this._ins || (this._ins = new NxWeb3());
  }

  // @ts-ignore
  web3: Web3;

  isInited: boolean = false;

  accountAddress: string = '';

  balance: number = 0;

  price: number = 0.08;

  get ethBalance(): number {
    return this.dether(this.balance);
  }

  get address(): string {
    return this.accountAddress;
  }

  // 测试合约地址
  contractAddress: string = process.env.NODE_ENV === 'development' ? devContractAddress : proContractAddress;

  contractAbi: object[] = process.env.NODE_ENV === 'development' ? devAbi : proAbi;

  async takeConstructor(callback: networkChangeCallback, accountaccountCallbackback: accountChangeCallback) {
    this.isInited = await this.init();
    this.ChainId = await this.getChainId()
    if (this.isInited) {
      await this.connect();
      this.listenNetworkChange(accountaccountCallbackback);
      this.listenAccountChange(accountaccountCallbackback);
    }
  }

  async init(): Promise<boolean> {
    try {
      // @ts-ignore
      if (!ethereum || !ethereum.isMetaMask) {
        alert('plase install MetaMask.');
        return false;
      }
      // @ts-ignore
      if (!window.web3) {
        alert('Mint in MetaMask browser.');
        return false;
      }

      // @ts-ignore
      this.web3 = new Web3(window.ethereum);
      // @ts-ignore
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      return true;
    } catch (error) {
      // alert('Mint in MetaMask browser.');
      alert('Please make sure your metamask is logged in!')
      return false;
    }
  }

  async getChainId(): Promise<number> {
    const id: number = await this.web3.eth.getChainId();
    return id;
  }

  async connect() {
    try {
      // @ts-ignore
      const rst = await this.web3.eth.getAccounts();
      this.accountAddress = rst[0];

      // this.balance = parseFloat(await this.web3.eth.getBalance(this.accountAddress));
    } catch (error) {
      console.log(error);
    }
  }

  listenAccountChange(accountaccountCallbackback: accountChangeCallback) {
    if (this.isInited) {
      // @ts-ignore
      window.ethereum.on('accountsChanged', async (accounts) => {
        this.accountAddress = this.web3.utils.toChecksumAddress(accounts[0]);
        // this.balance = parseFloat(await this.web3.eth.getBalance(this.accountAddress));
        console.log(this.ChainId)


        accountaccountCallbackback && accountaccountCallbackback()
      });
    }
  }

  listenNetworkChange(callback: networkChangeCallback) {
    if (this.isInited) {
      // @ts-ignore
      window.ethereum.on('chainChanged', async (_) => {
        const rst = await this.web3.eth.getAccounts();
        this.accountAddress = this.web3.utils.toChecksumAddress(rst[0]);
        this.ChainId = await this.getChainId()
        console.log(this.ChainId)

        // this.balance = parseFloat(await this.web3.eth.getBalance(this.accountAddress));
        callback && callback();
      });
    }
  }

  async preMint(count: number) {
    try {
      // const tokenIttem = whiteListToken.find((item: { address: string; }) => item.address === this.accountAddress);
      // if (!tokenIttem) {
      //   return '没有权限'
      // }
      // const token = tokenIttem.token;
      // console.log('%ctoken-------: ', 'color: MidnightBlue; background: Aquamarine; font-size: 18px;', token);
      const factoryContract = new this.web3.eth.Contract(
        // @ts-ignore
        this.contractAbi,
        this.contractAddress,
      );
      await factoryContract.methods
        // .preMint('MF', token)
        .preMint()
        .send({ from: this.accountAddress, value: this.ether(this.price * count) })

      return true;
    } catch (error: any) {
      return error;
    }
  }

  async publicMint(count: number) {
    try {
      const factoryContract = new this.web3.eth.Contract(
        // @ts-ignore
        this.contractAbi,
        this.contractAddress,
      );
      await factoryContract.methods
        .publicMint()
        .send({ from: this.accountAddress, value: this.ether(0.1 * count) });

      return true;
    } catch (error: any) {
      return error;
    }
  }

  ether(eth: Number): number {
    return parseInt(this.web3.utils.toWei(eth.toString()), 10);
  }

  dether(eth: number): number {
    return parseFloat((eth / 1000000000000000000).toFixed(2));
  }

  async getToken() {
    const abi = new ethers.utils.AbiCoder();
    let pack = abi.encode(["string", "address", "address"], ["MF", "0x4b765ae7e9e7cf4150b6d35cc3e858c418f32489", this.accountAddress])
    const hash = ethers.utils.keccak256(pack);
    console.log(hash);
    let wallet_sign = new ethers.Wallet('0x260ea1f25531a6c62ff1f29353b91283e090a8d022dbc7d034c82113ef4f760a')
    const token = wallet_sign.signMessage(ethers.utils.arrayify(hash));
    console.log('%ctoken: ', 'color: MidnightBlue; background: Aquamarine; font-size: 18px;', token);
    return token;
  }

  // async sign(msg: string): Promise<string> {
  //   try {
  //     //@ts-ignore
  //     const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
  //     const signature = await signer.signMessage(msg);
  //     const signerAddr = await signer.getAddress();
  //     console.log('signerAddr', signerAddr);
  //     return signature;
  //   } catch (error) {
  //     //@ts-ignore
  //     return error.toString()
  //   }
  // }

  // async verify({ msg, addr, sig }: { msg: string, addr: string, sig: string }): Promise<boolean> {
  //   try {
  //     const signerAddr = await ethers.utils.verifyMessage(msg, sig);
  //     console.log(addr, signerAddr)
  //     if (addr !== signerAddr) {
  //       return false;
  //     }
  //     return true;
  //   } catch (error) {
  //     console.log(error)
  //     return false
  //   }
  // }

  async WalletBagConnect(callback?: Function) {
    const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal });
    this.WalletConnector = connector;
    this.accountAddress = connector.accounts[0];
    // console.log(connector)
    this.ChainId = connector.chainId
    callback && callback(connector.chainId)
    // check if already connected
    if (!connector.connected) {
      // create new session
      await connector.createSession()
    }
  }

  initWalletConnect() {
    let connector: WalletConnect | undefined = undefined;
    if (this.WalletConnector) {
      connector = this.WalletConnector;
    } else {
      connector = new WalletConnect({ bridge });
    }

    if (connector) {
      connector.killSession();
    }
  }

  async preMintCount(count: number = 1, onSuccess?: Function, onError?: Function | undefined) {
    try {
      const factoryContract = new this.web3.eth.Contract(
        // @ts-ignore
        this.contractAbi,
        this.contractAddress,
      );
      return await factoryContract.methods
        .publicMint()
        .send({ from: this.accountAddress, value: this.ether(this.price * count) })
        .on('transactionHash', () => {
          onSuccess && onSuccess()
        })

    } catch (error: any) {
      onError && onError(error)
      // return error;
    }
  }
  async sendTransaction(count: number = 1, onSuccess?: Function, onError?: Function) {
    if (!this.WalletConnector) {
      linkWalletConnect()
    }

    const from = this.WalletConnector.accounts[0];

    // const to = address; // 合约地址
    const to = process.env.NODE_ENV === 'development' ? devContractAddress : proContractAddress;
    // const to = this.WalletConnector.accounts[0]; 

    const _nonce = await apiGetAccountNonce(this.WalletConnector.accounts[0], this.WalletConnector.chainId);
    const nonce = sanitizeHex(convertStringToHex(_nonce));

    const gasPrices = await apiGetGasPrices();
    const _gasPrice = gasPrices.slow.price;
    const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)));

    const _gasLimit = 21000;
    const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));

    const _value = this.price * 1000000000000000000 * count;

    const value = sanitizeHex(convertStringToHex(_value));
    console.log('%cvalue: ', 'color: MidnightBlue; background: Aquamarine; font-size: 18px;', value);

    // @ts-ignore
    const _web3 = new Web3(window.ethereum);
    const factoryContract = new _web3.eth.Contract(
      // @ts-ignore
      this.contractAbi,
      this.contractAddress,
    );
    const data = await factoryContract.methods
      .publicMint().encodeABI()

    // const data = '0x';

    const tx = {
      from,
      to,
      nonce,
      gasPrice,
      gasLimit,
      value,
      data,
    }
    try {
      const result = await this.WalletConnector.sendTransaction(tx);

      const formattedResult = {
        method: "eth_sendTransaction",
        txHash: result,
        from: from,
        to: to,
        value: `${value} ETH`,
      }
      console.log('%cformattedResult: ', 'color: MidnightBlue; background: Aquamarine; font-size: 18px;', formattedResult);
      onSuccess && onSuccess(false)
      // toast.success('购买成功！等待区块链确认')
    } catch (error: any) {
      console.log('%cError: ', 'color: MidnightBlue; background: Aquamarine; font-size: 18px;', error);
      // toast.error(error.message)

      onError && onError(error)
    }
  }
}

export const linkWallet = async (accountaccountCallbackback: accountChangeCallback): Promise<boolean> => {
  console.log(NxWeb3.instance.isInited)
  // 如果没有链接钱包，就去链接钱包
  // if (!NxWeb3.instance.isInited) {
  await NxWeb3.instance.takeConstructor(() => { }, accountaccountCallbackback);
  // }

  if (!NxWeb3.instance.isInited) {
    return false;
  }

  return true;
}

export const isMianChain = async (): Promise<boolean> => {
  const chainId = await NxWeb3.instance.getChainId();
  if (chainId !== 4) {
    return false;
  }
  return true;
}

export const linkWalletConnect = async (callback?: Function) => {
  await NxWeb3.instance.WalletBagConnect(callback)

}