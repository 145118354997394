/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TwitterIcon from '../../assets/images/icon_twitter.png';
import DiscordIcon from '../../assets/images/icon_discord.png';
import InstagramIcon from '../../assets/images/icon_ins.png';
import YoutubeIcon from '../../assets/images/icon_ytb.png';
import './index.css'

function MobileFooter(){
  return(
    <>
    <div className="mobileFooter">
      <div className="contain">
        <div className="foot_con">
          <div className="name">
          {/* MetaGenesis Contact */}
          <img src={require('../../assets/images/newlogo.gif').default} alt="" height='80'/>
          </div>
          <ul className="icon_item">
            <li className="item">
              <a href="https://twitter.com/MetaGenesisNFT" target="_blank">
                <img src={TwitterIcon} alt="twitter icon" width="100%" />
              </a>
            </li>
            <li className="item">
              <a href="https://discord.gg/UKsv4JMBg7" target="_blank">
                <img src={DiscordIcon} alt="discord icon" width="100%" />
              </a>
            </li>
            <li className="item">
              <a href="https://www.youtube.com/channel/UCDr1Y-bv5FBGePCJq72dwxQ" target="_blank">
                <img src={YoutubeIcon} alt="youtobe icon" width="100%" />
              </a>
            </li>
            <li className="item">
              <a href="https://www.instagram.com/MetaGenesis_2022/" target="_blank">
                <img src={InstagramIcon} alt="ins icon" width="100%" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </>
  )
}

export default MobileFooter