import React, { useState } from 'react'
// import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components';
import './index.css'
import LogoImage from '../../assets/images/newlogo.png';
import popBG from '../../assets/images/popbg.png'


const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ImageBox = styled.div`
  /* width: .4rem; */
  height: .6rem;
  margin-right: .16rem;
`;

const PoP = styled.div`
width: 0.6rem;
line-height: .4rem;
font-size: .2rem;
position: absolute;
right: 0;
top: -.2rem;
transform: translateX(100%);
background: url(${popBG}) no-repeat center;
background-size: contain;
display: flex;
align-items: center;
justify-content: center;
color: #000;
`

// const SiteName = styled.span`
//   font-size: .225rem;
//   letter-spacing: -0.01rem;
//   line-height: 1;
// `;

function Header() {
  const [navBtn, setNavBtnClassNames] = useState('iconUl');
  const [navMolde, setNavModelClassNames] = useState('nav-model');

  const changeNavStatu = () => {
    let isActive = navBtn.indexOf("active");
    if (isActive === -1) {
      setNavBtnClassNames('iconUl active')
      setNavModelClassNames('nav-model active')
    } else {
      setNavBtnClassNames('iconUl')
      setNavModelClassNames('nav-model')
    }
  }

  const liClick = () => {
    setNavBtnClassNames('iconUl')
    setNavModelClassNames('nav-model')
  }
  return (
    <>
      <div className="header">
        <div className="container">
          <div className="flex align-center">
            <LogoContainer>
              <ImageBox>
                <img src={LogoImage} alt="Logo" height="100%" />
              </ImageBox>
              {/* <SiteName className="titleFont">
                METAGENESIS
              </SiteName> */}
            </LogoContainer>
            <div className="spec"></div>
            <div className="navBtn icon">
              <ul className={navBtn} onClick={changeNavStatu}>
                <li></li>
                <li></li>
                <li></li>
              </ul>

            </div>
          </div>
        </div>
      </div>
      <div className={navMolde}>
        <div className="model-content">
          <div className="phoneNav">
            <ul className='navList textList'>
              <li className="item flex">
                <a className="flex" href="/#banner" onClick={liClick}>
                  <span>Home</span>
                  <div className="spec"></div>
                  {/* <img src={require('../../assets/images/icon_black.png').default} alt="" width='24' /> */}
                </a>
              </li>
              <li className="item flex">
                <a className="flex " href="/#Mint" onClick={liClick}>
                  <span className='mint'>
                    Mint
                    <PoP className="pop">
                      ING
                    </PoP>
                  </span>

                  <div className="spec"></div>
                  {/* <img src={require('../../assets/images/icon_black.png').default} alt="" width='24' /> */}
                </a>
              </li>
              <li className="item flex">
                <a className="flex" href="/#NFT" onClick={liClick}>
                  <span>NFT</span>
                  <div className="spec"></div>
                  {/* <img src={require('../../assets/images/icon_black.png').default} alt="" width='24' /> */}
                </a>
              </li>
              <li className="item flex">
                <a className="flex" href="/#GamePlay" onClick={liClick}>
                  <span>GamePlay</span>
                  <div className="spec"></div>
                  {/* <img src={require('../../assets/images/icon_black.png').default} alt="" width='24' /> */}
                </a>
              </li>
              <li className="item flex">
                <a className="flex" href="/#Characters" onClick={liClick}>
                  <span>Characters</span>
                  <div className="spec"></div>
                  {/* <img src={require('../../assets/images/icon_black.png').default} alt="" width='24' /> */}
                </a>
              </li>
              <li className="item flex">
                <a className="flex" href="/#RoadMap" onClick={liClick}>
                  <span>RoadMap</span>
                  <div className="spec"></div>
                  {/* <img src={require('../../assets/images/icon_black.png').default} alt="" width='24' /> */}
                </a>
              </li>
              <li className="item flex">
                <a className="flex" href="/#FAQ" onClick={liClick}>
                  <span>FAQ</span>
                  <div className="spec"></div>
                  {/* <img src={require('../../assets/images/icon_black.png').default} alt="" width='24' /> */}
                </a>
              </li>
              <li className="item flex">
                <a className="flex" href="/#Team" onClick={liClick}>
                  <span>Team</span>
                  <div className="spec"></div>
                  {/* <img src={require('../../assets/images/icon_black.png').default} alt="" width='24' /> */}
                </a>
              </li>
              <li className="item flex">
                <a className="flex" href="/#banner" onClick={liClick}>
                  <span>WhitePaper</span>
                  <div className="spec"></div>
                  {/* <img src={require('../../assets/images/icon_black.png').default} alt="" width='24' /> */}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header