/* eslint-disable react/jsx-no-target-blank */
import React, { useRef, useState } from 'react'
import styled from 'styled-components';
import TwitterIcon from '../../assets/images/icon_twitter.png';
import DiscordIcon from '../../assets/images/icon_discord.png';
import InstagramIcon from '../../assets/images/icon_ins.png';
import YoutubeIcon from '../../assets/images/icon_ytb.png';
import './index.css'
import RenewPublicSale from '../../mobileMint';
import { Modal } from 'antd';
const SocialBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: .8rem 0;
  margin-right: .2rem
`;

const SocoalButton = styled.div`
  width: .48rem;
  height: .48rem;
  margin-right: .2rem;
  box-sizing: border-box;
  background-color: transparent;
  border: 1.5px solid #FFFFFF;
  transition: all .5s linear;
  :hover{
    background-color: #333333;
    border-color: transparent;
  }
`;

function judgeClient() {
  let client = '';
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
    client = 'iOS';
  } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
    client = 'Android';
  } else {
    client = 'PC';
  }
  return client;
}

function Banner() {

  const [visible, setVisible] = useState(false);
  const [mobileType] = useState(judgeClient())
  const modelVideo = useRef<any>();
  const MoldeBackGround = {
    background: '#000',
    padding: 0,
  }
  const maskStyle = {
    background: '#000000'
  }
  return (
    <>
      <div className="banner mobileBanner" id='banner'>
        <div className="bg">

          <div className="bg-transfram">
            <div className="bg-container">
              <div className="pc-marqueee pc-marqueee_top"></div>
              <div className="pc-marqueee pc-marqueee_bottom"></div>
            </div>
          </div>
          <div className="sceneText">
            <div className="text">
              <p>The First</p>
              <p>VR FPS Game</p>
              <p>On The Blockchain</p>
            </div>
          </div>
        </div>


        <RenewPublicSale></RenewPublicSale>
        <SocialBox>
          <SocoalButton>
            <a href="https://twitter.com/MetaGenesisNFT" className="specialCursor" target="_blank">
              <img src={TwitterIcon} alt="twitter icon" width="100%" height="100%" />
            </a>
          </SocoalButton>
          <SocoalButton>
            <a href="https://discord.gg/UKsv4JMBg7" className="specialCursor" target="_blank">
              <img src={DiscordIcon} alt="twitter icon" width="100%" height="100%" />
            </a>
          </SocoalButton>
          <SocoalButton>
            <a href="https://www.youtube.com/channel/UCDr1Y-bv5FBGePCJq72dwxQ" className="specialCursor" target="_blank">
              <img src={YoutubeIcon} alt="youtube icon" width="100%" height="100%" />
            </a>
          </SocoalButton>
          <SocoalButton>
            <a href="https://www.instagram.com/MetaGenesis_2022/" className="specialCursor" target="_blank">
              <img src={InstagramIcon} alt="ins icon" width="100%" height="100%" />
            </a>
          </SocoalButton>
        </SocialBox>
        <div className="model_box">

          <div className={['video_box', mobileType === 'iOS' ? 'ios' : 'pc'].join(' ')}>
            <img src={require('../../assets/images/video_cover.jpg').default} alt="" width='100%' />
            <div className="btn_pause" onClick={() => { setVisible(true); setTimeout(() => { modelVideo.current.play() }, 500) }}>
              <img src={require('../../assets/images/play.png').default} alt="" width='100%' />
            </div>
          </div>
          {
            mobileType === 'iOS'
              ? <video ref={modelVideo} className="model_video" loop controls src={require('../../assets/video/model_video.mp4').default} width="100%"></video>
              : <Modal
                visible={visible}
                footer={null}
                bodyStyle={MoldeBackGround}
                centered={true}
                maskStyle={maskStyle}
                onCancel={() => { setVisible(false); modelVideo.current.pause() }}
                wrapClassName={'video_model'}
              >
                <video ref={modelVideo} className="model_video" loop controls src={require('../../assets/video/model_video.mp4').default} width="100%"></video>
              </Modal>
          }


        </div>

      </div>
    </>
  )
}

export default Banner