import styled from "styled-components";
import Appbar from "../components/appbar";
import CharactersScreen from "../pages/screen_characters";
import GameScreen from "../pages/screen_game";
// import HomeScreen from "../pages/screen_home";
import StoryScreen from "../pages/screen_story";
import RoadMap from "../pages/roadMap";
import Faq from "../pages/faq";
import Team from "../pages/team";
import Footer from "../pages/footer";
import React from "react";
import Races from "../pages/races";
// import PublicSaleCountDown from "../PublicSale";
// import PublicSaleLive from "../PublicSaleLive";
// import RenewPublicSale from "../renewPublicSale";
import NewBanner from "../pages/banner";
// import GameScreen2 from "../pages/screen_game/index copy";
// import GameScreen3 from "../pages/screen_game/index copy 3";
// import KeyFrame from "../pages/keyFrame";

const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  /* position: relative; */
`;




const HomePage = (): JSX.Element => {
  return (
    <>
      <AppContainer>
        <Appbar />
        {/* <HomeScreen /> */}
        <NewBanner />
        {/* <RenewPublicSale /> */}
        <StoryScreen />
        <Races />
        <GameScreen />
        <CharactersScreen />
        <RoadMap></RoadMap>
        <Faq></Faq>
        <Team></Team>
        <Footer></Footer>

      </AppContainer>
    </>
  );
}

export default HomePage;
