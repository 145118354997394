import React from "react";

function UpComing(){
  return(
    <div className="imgbox">
      <img src={require('../assets/upcoming/clownKiller.jpg').default} alt="" />
      <img src={require('../assets/upcoming/DashMecha.jpg').default} alt="" />
      <img src={require('../assets/upcoming/galaxySkin.jpg').default} alt="" />
      <img src={require('../assets/upcoming/seniorSoldier.jpg').default} alt="" />
    </div>
  )
}

export default UpComing