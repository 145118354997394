import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import './index.css'



function MobileRaces(){
  return(
    <div className="mobileRaces">
      <div className="mobile_title text-center">
          Races
      </div>
      <ul className="racesList">
      <ScrollAnimation 
          animateIn="slideInLeft"
          animateOut="fadeOut"
          duration={1}
          delay={0}
          >
        <li className="item">
          <img src={require('../../assets/races/races1.jpg').default} alt="" width='100%'/>
        </li>
        </ScrollAnimation>

        <ScrollAnimation 
        animateIn="slideInRight"
        animateOut="fadeOut"
        duration={1}
        delay={0}
        >
          <li className="item">
            <img src={require('../../assets/races/races2.jpg').default} alt="" width='100%'/>
          </li>
        </ScrollAnimation>
        
        <ScrollAnimation 
        animateIn="slideInLeft"
        animateOut="fadeOut"
        duration={1}
        delay={0}
        >
          <li className="item">
            <img src={require('../../assets/races/races3.jpg').default} alt="" width='100%'/>
          </li>
        </ScrollAnimation>
        
        <ScrollAnimation 
        animateIn="slideInRight"
        animateOut="fadeOut"
        duration={1}
        delay={0}
        >
          <li className="item">
            <img src={require('../../assets/races/races4.jpg').default} alt="" width='100%'/>
          </li>
        </ScrollAnimation>
      </ul>
    </div>
  )
}

export default MobileRaces;