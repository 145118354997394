import React from "react";
import styled from "styled-components";
import ScrollAnimation from 'react-animate-on-scroll';
import './index.css'
const RoadMapBox = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  z-index: 1;
  position: relative;
  margin-top: .5rem;
`;

function RoadMap(){

  return(
    <>
      <div id='RoadMap' className="roadMap mobile_roadmap">
        <RoadMapBox>
          <div className="mobile_title">
            ROADMAP
          </div>
          <div className='mobileTimeline'>
            <ScrollAnimation 
            animateIn="slideInUp"
            animateOut="fadeOut"
            duration={0.5}
            delay={0}
            >
              <div className="timeline-item">
                <div className="time">
                  <ScrollAnimation animateIn="fadeInLeft">
                  <span className="num">1</span>
                  <div className="year_box">
                    <span className='stage name'>December</span>
                    <span className="year">2021</span>
                  </div>
                  </ScrollAnimation>
                </div>
                <div className="content">
                <ScrollAnimation animateIn="fadeInRight">
                  <p>Official website build and launch</p>
                  <p>Social launch</p>
                  <p>Marketing plan</p>
                  <p>Character NFT design and creation</p>
                </ScrollAnimation>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation 
            animateIn="slideInUp"
            animateOut="fadeOut"
            duration={0.5}
            delay={0}
            >
              <div className="timeline-item">
                <div className="time">
                  <ScrollAnimation animateIn="fadeInLeft">
                    <span className="num">2</span>
                    <div className="year_box">
                      <span className='stage'>Q1</span>
                      <span className="year">2022</span>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="content">
                  <ScrollAnimation animateIn="fadeInRight">
                  <p>Marketing / Presale</p>
                  <p>Public sale</p>
                  <p>Community rewards, NFT giveaway</p>
                  <p>Process 3D character avatar making </p>
                  <p>Reveal the weapon system in the game </p> 
                  <p>Game lite paper launch, start to prepare the MG weapon project </p>
                  <p>Fully start game developing, start to launch the token</p>
                  </ScrollAnimation>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation 
            animateIn="slideInUp"
            animateOut="fadeOut"
            duration={0.5}
            delay={0}
            >
              <div className="timeline-item">
                <div className="time">
                <ScrollAnimation animateIn="fadeInLeft">
                <span className="num">3</span>
                <div className="year_box">
                  <span className='stage'>Q2</span>
                  <span className="year">2022</span>
                </div>
                </ScrollAnimation>
                </div>
                <div className="content">
                <ScrollAnimation animateIn="fadeInRight">
                  <p>MG weapon NFT presale</p>
                  <div className="indentation">
                  Owners of MG projects which characters are weaponed can receive their own weapon NFTs for free on the day of the presale
                  </div>
                  <p>MG weapon NFT Public sale </p>
                </ScrollAnimation>
                </div>
              </div>
            </ScrollAnimation> 
            <ScrollAnimation 
            animateIn="slideInUp"
            animateOut="fadeOut"
            duration={0.5}
            delay={0}
            >
              <div className="timeline-item">
                <div className="time">
                  <ScrollAnimation animateIn="fadeInLeft">
                  <span className="num">4</span>
                  <div className="year_box">
                    <span className='stage'>Q3</span>
                    <span className="year">2022</span>
                  </div>
                  </ScrollAnimation>
                  
                </div>
                <div className="content">
                  <ScrollAnimation animateIn="fadeInRight">
                  <p>Game trailer/ Demo video</p>
                  <p>Official launch of $MGT</p>
                  <p>Alpha-version gameplay video</p>
                  <p>PVE, PVP game mode test</p>
                  <p>PVP mode alpha invitational tournament</p>
                  </ScrollAnimation>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation 
            animateIn="slideInUp"
            animateOut="fadeOut"
            duration={0.5}
            delay={0}
            >
              <div className="timeline-item">
                <div className="time">
                  <ScrollAnimation animateIn="fadeInLeft">
                  <span className="num">5</span>
                  <div className="year_box">
                    <span className='stage'>Q4</span>
                    <span className="year">2022</span>
                  </div>
                  </ScrollAnimation>
                  
                </div>
                <div className="content">
                  <ScrollAnimation animateIn="fadeInRight">
                  <p>Survival game mode test</p>
                  <p>Launch guild system</p>
                  <p>MGT staking rewards</p>
                  <p>Fully grown game economy and market</p>
                  </ScrollAnimation>
                </div>
              </div>
            </ScrollAnimation>
            
          </div>
        </RoadMapBox>
        
      </div>
    </>
  )
}

export default RoadMap