const data = [
    {
        q: 'How many Metaheroes are there?',
        w: "There are a total of 9999 Metaheroes on the Ethereum blockchain.",
    },
    {
        q: "How much is each mint?",
        w: "We offer each one at 0.08 ETH to mint.",
    },
    {
        q: "How many can I mint per transaction?",
        w: "You can mint 5 Meta heroes per transaction through our website when public sale.",
    },
    {
        q: "How are the rarity traits determined?",
        w: 'Each Metaheroes has been created with a variety of rarity traits. We use generative processes to generate these traits and distribute the rarity across all Metaheroes. Listing will be made on raritytools.com.',
    },
    {
        q: "What do I get as an early adopter?",
        w: "As an early adopter, you will get early access to our drop so you can get chances to mint before everyone else. In addition, early adopters get special access to giveaways, contests, and more, so stay tuned!",
    },
]


export default data;





















