import React, { useState, useEffect, useRef } from "react";
import './index.css'
import styled from "styled-components";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'
import SwiperCore, { Mousewheel, Scrollbar, Navigation } from "swiper";
SwiperCore.use([Mousewheel, Scrollbar, Navigation]);

const SlideContainer = styled.div`
  /* height: calc(100vh - 2.74rem); */
  // height: 100vh;
  /* display: flex;
  align-items: flex-end;
  justify-content: flex-end; */
  position: relative;
  .swiper{
    width: 100%;
    // height: calc(100vh - 2.74rem); 
    // height: 100%;
  }
`;

function GameScreen() {
  const [title, setTitle] = useState('PLAY TO EARN');
  const GamePlay = useRef<any>()
  useEffect(() => {

  }, [])

  const GamePlayWheel = () => {
    // console.log(GamePlay.current.getBoundingClientRect())
    // GamePlay.current.getBoundingClientRect().top = 0
  }

  const ActiveIndexChange = (e: any) => {
    if (e.activeIndex === 4) {
      setTitle('GAME PLAY')
    } else {
      setTitle('PLAY TO EARN')
    }
  }
  return (
    <div id='GamePlay' className="game_screen" ref={GamePlay} onMouseMove={GamePlayWheel}>

      <SlideContainer className='game_scroll_box'>
        <div className="title_box">
          <h1 className="title text-center">Game overview</h1>
          <div className="sub_title">{title}</div>
        </div>
        <Swiper
          mousewheel={{
            releaseOnEdges: true,
          }}
          scrollbar={{
            hide: true
          }}
          navigation={true}
          onActiveIndexChange={(e) => { ActiveIndexChange(e) }}
        >
          <SwiperSlide>
            <div className="item">
              <div className="item_contain">
                <div className="flex">
                  <div className="img_box">
                    <video width="100%" preload="auto" autoPlay loop muted playsInline>
                      <source src={require('../../assets/video/getcharacter.mp4').default} type="video/mp4" />
                    </video>
                  </div>
                  <div className="text_box">
                    <h3 className="ord">1</h3>
                    <h1>Get your character in game, join in the squad</h1>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="item">
              <div className="item_contain">
                <div className="flex">
                  <div className="img_box">
                    <video width="100%" preload="auto" autoPlay loop muted playsInline>
                      <source src={require('../../assets/video/equipweapon.webm').default} type="video/webm" />
                      <source src={require('../../assets/video/equipweapon.mp4').default} type="video/mp4" />
                    </video>
                  </div>
                  <div className="text_box">
                    <h3 className="ord">2</h3>

                    <h1>Equip them with the amazing weapon</h1>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="item">
              <div className="item_contain">
                <div className="flex">
                  <div className="img_box">
                    <video width="100%" preload="auto" autoPlay loop muted playsInline>
                      <source src={require('../../assets/video/upgradeweapon.webm').default} type="video/webm" />
                      <source src={require('../../assets/video/upgradeweapon.mp4').default} type="video/mp4" />
                    </video>
                  </div>
                  <div className="text_box">
                    <h3 className="ord">3</h3>

                    <h1>Upgrade your weapon in a dual-token system</h1>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="item">
              <div className="item_contain">
                <div className="flex">
                  <div className="img_box">
                    <video width="100%" preload="auto" autoPlay loop muted playsInline>
                      <source src={require('../../assets/video/bag.webm').default} type="video/webm" />
                      <source src={require('../../assets/video/bag.mp4').default} type="video/mp4" />
                    </video>
                  </div>
                  <div className="text_box">
                    <h3 className="ord">4</h3>

                    <h1>Combat with other players and get rewards</h1>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="item">
              <div className="play_list_box" style={{ width: '100%' }}>
                <ul className="play_list">
                  <li className="play_item">
                    <div className="img_box">
                      <img src={require('../../assets/images/game_type1.png').default} alt="" width='100%' />
                    </div>
                    <div className="text_box">
                      <div className="title">PVE</div>
                      <h4 className="name">Adventure Mode</h4>
                      <div className="disc">
                        Upgrade your hero in different levels
                      </div>
                    </div>
                  </li>
                  <li className="play_item">
                    <div className="img_box">
                      <img src={require('../../assets/images/game_type2.png').default} alt="" width='100%' />
                    </div>
                    <div className="text_box">
                      <div className="title">PVP</div>
                      <h4 className="name">Match Mode</h4>
                      <div className="disc">
                        Win the 5v5 game, get token rewards.
                      </div>
                      <h4 className="name">Survival Mode</h4>
                      <div className="disc">
                        Join 3-PLAYERS squad, combat with 99 players, survive till the end.
                      </div>
                    </div>
                  </li>
                  <li className="play_item">
                    <div className="img_box">
                      <img src={require('../../assets/images/game_type3.png').default} alt="" width='100%' />
                    </div>
                    <div className="text_box">
                      <h4 className="title">Guild Mode</h4>
                      <div className="disc">
                        Complete the task, share the benefits with your teammates.
                      </div>
                    </div>
                  </li>
                  <li className="play_item">
                    <div className="img_box">
                      <img src={require('../../assets/images/game_type4.png').default} alt="" width='100%' />
                    </div>
                    <div className="text_box">
                      <h4 className="title">Discover Mode</h4>
                      <div className="disc">
                        Self-Construct layout and in gameplay mode.
                      </div>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </SlideContainer>
    </div>
  )
}

export default GameScreen