import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import './index.css'
const RoadMapBox = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  z-index: 1;
  position: relative;
`;

const RoadMapTitle = styled.p`
  text-align: center;
  margin: .7rem auto 1.74rem;
  font-size: .72rem;
  position: relative;
`;

function RoadMap() {
  // const  currentScroll = useRef(0)
  const propssHeight = useRef(0)
  const lineProgress = useRef<any>()
  useEffect(() => {
    window.addEventListener('scroll', scrollWind)
  }, [])
  const scrollWind = () => {
    const lineTop = document.getElementsByClassName('timeline__line')[0].getBoundingClientRect().top
    const progressTop = document.getElementsByClassName('timeline__line-progress')[0]
    const timelineItem = document.getElementsByClassName('timeline-item')

    propssHeight.current = window.innerHeight - 200 - lineTop;
    if (progressTop.getBoundingClientRect().top > window.innerHeight - 200) propssHeight.current = 0;
    gsap.to([lineProgress.current], {
      height: `${propssHeight.current}px`
    })

    if (lineTop > window.innerHeight - 300) return


    for (let i = 0; i < timelineItem.length; i++) {
      const item = timelineItem[i]
      const itemTop = item.getBoundingClientRect().top;
      // item.classList.
      if (itemTop > window.innerHeight - 200) {
        item.classList.remove('active')
      };
      if (itemTop <= window.innerHeight - 200) {
        item.classList.add('active')
      }
    }

  }
  return (
    <>
      <div id='RoadMap' className="roadMap">
        <RoadMapBox>
          {/* <div className="model_top roadmodel_box"></div> */}
          <RoadMapTitle className="title">
            ROADMAP
          </RoadMapTitle>
          <div className='timeline'>
            <div className="timeline__line" >
              <div className="timeline__line-progress" ref={lineProgress}></div>
            </div>
            <div className="timeline_list">
              <div className="timeline-item">
                <div className="time">
                  <span className="num">1</span>
                  <div className="year_box">
                    <span className='stage name'>December</span>
                    <span className="year">2021</span>
                  </div>
                </div>
                <div className="content">
                  <p>Official website build and launch</p>
                  <p>Social launch</p>
                  <p>Marketing plan</p>
                  <p>Character NFT design and creation</p>
                </div>
              </div>
              <div className="timeline-item">
                <div className="time">
                  <span className="num">2</span>
                  <div className="year_box">
                    <span className='stage'>Q1</span>
                    <span className="year">2022</span>
                  </div>
                </div>
                <div className="content">
                  <p>Marketing / Presale</p>
                  <p>Public sale</p>
                  <p>Community rewards, NFT giveaway</p>
                  <p>Process 3D character avatar making </p>
                  <p>Reveal the weapon system in the game </p>
                  <p>Game lite paper launch, start to prepare the MG weapon project </p>
                  <p>Fully start game developing, start to launch the token </p>
                </div>
              </div>
              <div className="timeline-item">
                <div className="time">
                  <span className="num">3</span>
                  <div className="year_box">
                    <span className='stage'>Q2</span>
                    <span className="year">2022</span>
                  </div>
                </div>
                <div className="content">
                  <p>MG weapon NFT presale</p>
                  <div className="indentation">
                    Owners of MG projects which characters are weaponed can receive their own weapon NFTs for free on the day of the presale
                  </div>
                  <p>MG weapon NFT Public sale </p>
                </div>
              </div>
              <div className="timeline-item">
                <div className="time">
                  <span className="num">4</span>
                  <div className="year_box">
                    <span className='stage'>Q3</span>
                    <span className="year">2022</span>
                  </div>
                </div>
                <div className="content">
                  <p>Game trailer/ Demo video</p>
                  <p>Official launch of $MGT</p>
                  <p>Alpha-version gameplay video</p>
                  <p>PVE, PVP game mode test</p>
                  <p>PVP mode alpha invitational tournament</p>
                </div>
              </div>
              <div className="timeline-item">
                <div className="time">
                  <span className="num">5</span>
                  <div className="year_box">
                    <span className='stage'>Q4</span>
                    <span className="year">2022</span>
                  </div>
                </div>
                <div className="content">
                  <p>Survival game mode test</p>
                  <p>Launch guild system</p>
                  <p>MGT staking rewards</p>
                  <p>Fully grown game economy and market</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="model_bottom roadmodel_box"></div> */}
        </RoadMapBox>
        {/* // <div className="scen">
        //   <div className="roadMapBg">
        //     <video width="100%" preload="auto" autoPlay loop muted playsInline>
        //       <source src={require('../../assets/video/maonvpiao.webm').default} type="video/mp4" />
        //     </video>
        //   </div>
        // </div> */}
      </div>
    </>
  )
}

export default RoadMap