import React from "react";
import './index.css'



function MobileTeam(){
  return(
    <>
      <div id='Team' className="mobileTeam">
        <div className="mobile_title">
          Team
        </div>
        <div className="contain">
          <ul className="core teamUl">
            <li>
              <div className="img_box">
                <img src={require('../../assets/team/team1.jpeg').default} alt="" width="100%"/>
              </div>
              <div className="text_box">
                <h1 className="name">
                Project Leader
                </h1>
                <p className="introduce">
                Over 6 years of experience in strategic management, an expert in building strong relations with crypto funds and investors.
                </p>
              </div>
            </li>
            <li>
              <div className="img_box">
                <img src={require('../../assets/team/team2.jpeg').default} alt="" width="100%"/>
              </div>
              <div className="text_box">
                <h1 className="name">
                Project Manager
                </h1>
                <p className="introduce">
                Professional game producer contributed to the release of more than 3 gaming products, which exceeded the 5m user mark.
                </p>
              </div>
            </li>
            <li>
              <div className="img_box">
                <img src={require('../../assets/team/team3.jpeg').default} alt="" width="100%"/>
              </div>
              <div className="text_box">
                <h1 className="name">
                Graphic Designer
                </h1>
                <p className="introduce">
                Lead Designer with over years of experience both in digital art and 3D animation.
                </p>
              </div>
            </li>
            <li>
              <div className="img_box">
                <img src={require('../../assets/team/team4.jpeg').default} alt="" width="100%"/>
              </div>
              <div className="text_box">
                <h1 className="name">
                Marketing
                </h1>
                <p className="introduce">
                Experienced Relations and Communications manager. With a background in World Economy and International Affairs.
                </p>
              </div>
            </li>
            <li>
              <div className="img_box">
                <img src={require('../../assets/team/team5.jpeg').default} alt="" width="100%"/>
              </div>
              <div className="text_box">
                <h1 className="name">
                Tech Development
                </h1>
                <p className="introduce">
                Has contributed to the development of multiple games, and got into crypto in 2021.
                </p>
              </div>
            </li>
            <li>
              <div className="img_box">
                <img src={require('../../assets/team/team6.jpeg').default} alt="" width="100%"/>
              </div>
              <div className="text_box">
                <h1 className="name">
                3D Artist
                </h1>
                <p className="introduce">
                8 years of experience in 3D modeling, and participated in the production of over 20 game projects.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default MobileTeam