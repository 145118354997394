import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'
import SwiperCore, { EffectCoverflow, Autoplay } from "swiper";
SwiperCore.use([EffectCoverflow, Autoplay]);

function StorySwiper() {
  return (
    <>
      <div className="swiper_contain">
        <Swiper
          loop={true}
          spaceBetween={40}
          slidesPerView={3}
          centeredSlides={true}
          speed={500}
          freeMode={true}
          autoplay={{
            "delay": 500,
            "disableOnInteraction": true,
          }}
        >
          {
            new Array(24).fill('0').map((el, i) => {
              return <SwiperSlide key={i}>
                <div className="swiper_img">
                  <img src={require(`../../assets/nft/nft${i + 1}.jpg`).default} alt={i.toString()} width="100%" height="100%" />
                </div>
              </SwiperSlide>;
            })
          }
        </Swiper>
      </div>
    </>
  )
}

export default StorySwiper