import React from 'react'
import './index.css'


function GameComp(){
  return(
    <div id='GamePlay' className="gameBlock mobile_block">
      <div className="mobile_title">Game overview</div>
      <div className="earn">
        <h3 className="gameTitle">
        PLAY TO EARN
        </h3>

        <div className="earnSwiper">
         
          <div className="item">
            <div className="item_contain">
              <div className="flex flexColumn">
                <div className="img_box">
                  <div className="video">
                    <video width="100%" autoPlay preload="auto" loop muted playsInline>
                      <source src={require('../../assets/video/getcharacter.mp4').default} type="video/mp4" />
                    </video>
                  </div>
                </div>
                <div className="text_box">
                  <h3 className="ord">1</h3>
                  <h1>Get your character in game, join in the squad</h1>
                </div>
              </div>
            </div>
          </div>
      
          <div className="item">
            <div className="item_contain">
              <div className="flex flexColumn">
                <div className="img_box">
                  <div className="video">
                    <video width="100%" autoPlay preload="auto" loop muted playsInline>
                      <source src={require('../../assets/video/equipweapon.mp4').default} type="video/mp4" />
                    </video>
                  </div>
                </div>
                <div className="text_box">
                  <h3 className="ord">2</h3>
                  <h1>Equip them with the amazing weapon</h1>
                </div>
              </div>
            </div>
          </div>
        
          <div className="item">
            <div className="item_contain">
              <div className="flex flexColumn">
                <div className="img_box">
                  <div className="video">
                    <video width="100%" autoPlay preload="auto" loop muted playsInline>
                      <source src={require('../../assets/video/upgradeweapon.mp4').default} type="video/mp4" />
                    </video>
                  </div>
                </div>
                <div className="text_box">
                  <h3 className="ord">3</h3>
                  <h1>Upgrade your weapon in a dual-token system</h1>
                </div>
              </div>
            </div>
          </div>
        
          <div className="item">
            <div className="item_contain">
              <div className="flex flexColumn">
                <div className="img_box">
                  <div className="video">
                    <video width="100%" autoPlay preload="auto" loop muted playsInline>
                      <source src={require('../../assets/video/bag.mp4').default} type="video/mp4" />
                    </video>
                  </div>
                </div>
                <div className="text_box">
                  <h3 className="ord">4</h3>
                  <h1>Combat with other players and get rewards</h1>
                </div>
              </div>
            </div>
          </div>
            
        </div>
      </div>
      <div className="game">
        <h3 className="gameTitle">
        GAME PLAY
        </h3>
        <div className="gameSwiper">
      
          <div className="play_item">
            <div className="img_box">
              <img src={require('../../assets/images/game_type1.png').default} alt="" width='100%'/>
            </div>
            <div className="text_box">
              <div className="title">PVE</div>
              <h4 className="name">Adventure Mode</h4>
              <div className="disc">
              Upgrade your hero in different levels
              </div>
            </div> 
          </div>
        
          <div className="play_item">
            <div className="img_box">
              <img src={require('../../assets/images/game_type2.png').default} alt="" width='100%'/>
            </div>
            <div className="text_box">
              <div className="title">PVP</div>
              <h4 className="name">Match Mode</h4>
              <div className="disc">
              Win the 5v5 game, get token rewards.
              </div>
              <h4 className="name mt_20">Survival Mode</h4>
              <div className="disc">
              Join 3-PLAYERS squad, combat with 99 players, survive till the end.
              </div>
            </div> 
          </div>
        
          <div className="play_item">
            <div className="img_box">
              <img src={require('../../assets/images/game_type3.png').default} alt="" width='100%'/>
            </div>
            <div className="text_box">
              <h4 className="title">Guild Mode</h4>
              <div className="disc">
              Complete the task, share the benefits with your teammates.
              </div>
            </div> 
          </div>
        
          <div className="play_item">
            <div className="img_box">
              <img src={require('../../assets/images/game_type4.png').default} alt="" width='100%'/>
            </div>
            <div className="text_box">
              <h4 className="title">Discover Mode</h4>
              <div className="disc">
              Self-Construct layout and in gameplay mode.
              </div>
            </div> 
          </div>
            
        </div>
      </div>
    </div>
  )
}

export default GameComp;