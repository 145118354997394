import { useCallback, useState } from "react";
import styled from "styled-components";
import './screen_characters.css'
const CharactersBox = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;  
`;

const CharactersTitle = styled.p`
  text-align: center;
  margin: .7rem auto 1.74rem;
  font-size: .72rem;
`;

let meateTextData = [
  {
    name: 'Gladys',
    story: "The former captain of the Marine Corps' Falcon Squad — Gladys, wandering in the land she once wanted to conquer. The city in front of her is a scene of disrepair, which reminds her of the life when she was a child. But the difference between the old days and now is that her strong faith and outstanding combat ability make Gladys believe that her power can bring the chaotic world back on track and find a new path for humanity.",
    skills: [
      {
        name: 'Precision Sniper',
        icon: 'skill1'
      },
      {
        name: 'Force Control ',
        icon: 'skill2'
      },
      {
        name: 'Speed Displacement',
        icon: 'skill3'
      }
    ]
  },
  {
    name: 'Flavia',
    story: "Whether it is superb combat ability or advanced weapon, are unable to defeat the powerful Camron. He is the strongest AI warrior known to the human world, was the leader of the Dresa Mecha Force, plenty of combat experience makes him undefeated. To improve his combat skills, he left the army and keep finding the warrior stronger than him.",
    skills: [
      {
        name: 'Flaming Guns',
        icon: 'skill4'
      },
      {
        name: 'Range Strike ',
        icon: 'skill5'
      },
      {
        name: 'Defensive Fortress',
        icon: 'skill6'
      }
    ]
  },
  {
    name: 'Camron',
    story: "Mysterious, seductive, ruthless are her definition. Flavia is the dream of every man in the world, but also their nightmare. She hates all the evil and injustice, ends up all this dirt is her destiny. With her unexpected swift assassination, she became more famous around the hired killers, even the most brutal criminal will stutter when they mention the name.",
    skills: [
      {
        name: 'Melee Kills',
        icon: 'skill7'
      },
      {
        name: 'Ultimate Sanctions',
        icon: 'skill8'
      },
      {
        name: 'Holographic seduction',
        icon: 'skill9'
      }
    ]
  }
]


const CharactersScreen = (): JSX.Element => {

  const [contModel, setContModel] = useState(false)
  const [selectIndex, setSelectIndex] = useState(null)
  const [showContent, setShowContent] = useState(false)
  const openModel = useCallback((index)=>{
    setContModel(true)
    setSelectIndex(index)
    setTimeout(()=>{setShowContent(true)},600)
  },[])

  
  return (
    <>
      <div id='Characters' className="Characters">
        <CharactersBox>
          <CharactersTitle className="title">
            CHARACTERS
          </CharactersTitle>
          <div className="charact_content">
              <div className={["charaters_list", contModel? 'padHide': ''].join(' ')} >
                <ul className="ul_box">
                  {
                    meateTextData.map((_ele, i) => {
                      return(
                        <li className={["list_item", selectIndex === i? 'active': '', showContent && selectIndex === i?'showCont': ''].join(' ')} key={i} onClick={()=>{openModel(i)}}>
                          <div className="img_contain">
                            <div className="bg" style={{backgroundImage: "url("+require(`../assets/images/meta_bg${i+1}.png`).default+")"}}>
                            </div>
                            <img src={require(`../assets/images/meta_avatar${i+1}.png`).default} alt=""/>
                          </div>
                          <div className="img_model">
                            <span className="liuguang">DETAILS <img src={require('../assets/images/arrow.png').default} alt="" width="20" height="20"/></span>
                          </div>
                          <div className="text_contain">
                            <h1 className="name">{_ele.name}</h1>
                            <p className="story">
                              {_ele.story}
                            </p>
                            <div className="skills_box">
                              <h6 className="skill_title">SKILLS</h6>
                              <ul className="skill_ul">
                                {
                                  _ele.skills.map((item, i)=>{
                                    return (
                                      <li className="skill_item" key={i}>
                                        <div className="icon">
                                          <img src={require(`../assets/images/icon/${item.icon}.png`).default} alt="" width='42'/>
                                        </div>
                                        <div className="skill_name">{item.name}</div>
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
                <div className="close_block" onClick={()=>{setContModel(false);setSelectIndex(null);setShowContent(false)}}></div>
            </div>
          </div>
        </CharactersBox>
      </div>
    </>
  );
}

export default CharactersScreen;
