import { includes } from "lodash";
import React, { useEffect, useState } from "react";
import CallBackNews from "../components/notification/call";
import { NxWeb3 } from "../libs/NxWeb3";
import './index.css'


function AirDrop() {
	const [isLoading, setIsLoading] = useState(false);
	const [hasWL, setHasWL] = useState<any>(true);

	const connectMateMask = async () => {
		if (!NxWeb3.instance.isConnected) {
			await NxWeb3.instance.connect().then(async () => {
				if (NxWeb3.instance.accountAddress !== '' && NxWeb3.instance.accountAddress !== undefined) {

					fetch(`${process.env.PUBLIC_URL}/data/airdrop.json`, {
						method: "GET",
						headers: {
							'Content-Type': 'application/json',
							'Accept': 'application/json'
						},
					})
						.then(response => response.json())
						.then(data => {
							// const hasRight = filter(data, { 'address': NxWeb3.instance.accountAddress })
							const hasRight = includes(data, NxWeb3.instance.accountAddress)

							if (!hasRight) {
								setHasWL(false)
							} else {
								setHasWL(true)
							}
						})
				}
			});
		}
	}

	// get mint count
	useEffect(() => {
		connectMateMask()
	})



	const mintPass = async () => {
		await NxWeb3.instance.connect();  // Prevent link when switching accounts

		// Get the chain ID to determine whether it is on the main network or the test network
		const chainId = await NxWeb3.instance.getChainId();
		if (chainId !== 1) {
			let data = {
				msg: 'Please use an account under the main network',
				type: 'error',
				className: 'failed',
				duration: 6000,
			}
			CallBackNews.NewsSend(data);
			return;
		}

		setIsLoading(true)
		try {
			await new NxWeb3.instance.web3.eth.Contract(
				// @ts-ignore
				NxWeb3.instance.contractAbi,
				NxWeb3.instance.contractAddress,
			).methods
				.airdropMint()
				.send({ from: NxWeb3.instance.accountAddress, value: NxWeb3.instance.ether(0) })
				.on('transactionHash', () => {
					console.log('return hash')
					let data = {
						msg: `Successful. Ethereum is confirming.`,
						type: 'success',
						className: 'success',
						duration: 3000,
					}
					CallBackNews.NewsSend(data);
					setIsLoading(false)
				});
		} catch (error: any) {
			let data = {
				msg: `${error.message}`,
				type: 'error',
				className: 'failed',
				duration: 3000,
			}
			CallBackNews.NewsSend(data);
			setIsLoading(false)
		}

	}

	return (
		<div className="mint_com">
			<div className="bg">
				<video height="100%" muted autoPlay loop playsInline>
					<source src={require('../assets/video/mintBg.mp4').default} type="video/mp4" />
				</video>
			</div>
			<div className="mint_model">
				<h1 className="title">Air Drop</h1>

				<div className="mintBtn">
					{
						hasWL
							? <button className={['mint_btn', isLoading ? 'loading' : ''].join(' ')} onClick={() => { mintPass() }}>
								{
									isLoading
										? <><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span><span>Minting! Please Wait…</span></>
										: <span>CLAIM</span>
								}
							</button>
							: <button className="mint_btn" disabled>You are not qualified to claim the airdrop</button>
					}
				</div>
			</div>
		</div>
	)
}

export default AirDrop;