import './mobile.css'
import styled from "styled-components";
import Header from "../mobile/header";
import Banner from "../mobile/banner";
import Story from "../mobile/story";
import GameComp from "../mobile/game";
import Characters from "../mobile/characters";
import RoadMap from '../mobile/roadMap';
import MobileFaq from '../mobile/faq';
import MobileTeam from '../mobile/team';
import MobileFooter from '../mobile/footer';
import MobileRaces from '../mobile/races';

const MobileContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #000000;
  padding-top: .8rem;
  overflow-x: hidden;
`;


const MobilePage = (): JSX.Element => {
  return (
    <>
      <MobileContainer className='auto-scroll'>
        <Header/>
        <Banner/>
        <Story/>
        <MobileRaces/>
        <GameComp/>
        <Characters/>
        <RoadMap/>
        <MobileFaq/>
        <MobileTeam></MobileTeam>
        <MobileFooter/>
      </MobileContainer>
    </>
  );
}

export default MobilePage;
