import React from "react";
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'
import './index.css'
import SwiperCore, { EffectCoverflow, Autoplay } from "swiper";
import ScrollAnimation from "react-animate-on-scroll";
SwiperCore.use([EffectCoverflow, Autoplay]);



function Races() {
  return (
    <div className="pcRaces">
      <div className="title text-center">
        Races
      </div>
      <ul className="racesList">
        <ScrollAnimation
          animateIn="slideInUp"
          animateOut="fadeOut"
          duration={1}
          delay={0}
        >
          <li className="item">
            <img src={require('../../assets/races/races1.jpg').default} alt="" width='100%' />
          </li>
          <li className="item">
            <img src={require('../../assets/races/races2.jpg').default} alt="" width='100%' />
          </li>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="slideInUp"
          animateOut="fadeOut"
          duration={1}
          delay={0}
        >
          <li className="item">
            <img src={require('../../assets/races/races3.jpg').default} alt="" width='100%' />
          </li>
          <li className="item">
            <img src={require('../../assets/races/races4.jpg').default} alt="" width='100%' />
          </li>
        </ScrollAnimation>
      </ul>
    </div>
  )
}

export default Races;