import Web3 from "web3";
import CallBackNews from "../components/notification/call";
import {
  devAbi,
  devContractAddress,
  proAbi,
  proContractAddress,
} from "./abi";

declare var window: any

export class NxWeb3 {
  private static _ins: NxWeb3;
  static get instance(): NxWeb3 {
    return this._ins || (this._ins = new NxWeb3());
  }

  // @ts-ignore
  web3: Web3;

  isInited: boolean;

  accountAddress: string = '';

  balance: number = 0;

  price: number = 0.08;

  maxCount: number = 6666;

  preMintCount: number = 0;

  get isConnected(): boolean {
    return this.accountAddress !== '' && this.accountAddress !== undefined;
  }

  totalNumber: number = 0;
  metaMaskConfirmation: boolean = false;


  // 测试合约地址
  contractAddress: string = process.env.NODE_ENV === 'development' ? devContractAddress : proContractAddress;

  contractAbi: object[] = process.env.NODE_ENV === 'development' ? devAbi : proAbi;

  constructor() {
    this.isInited = this.init();
  }

  init(): boolean {
    try {
      // @ts-ignore
      if (!ethereum || !ethereum.isMetaMask) {
        alert('plase install MetaMask.');
        return false;
      }
      // @ts-ignore
      // if (!window.web3) {
      //   return false;
      // }

      // @ts-ignore
      this.web3 = new Web3(window.ethereum);
      // @ts-ignore
      window.ethereum.enable();
      return true;
    } catch (error) {
      return false;
    }
  }

  async getChainId(): Promise<number> {
    const id: number = await this.web3.eth.getChainId();
    // const name = await this.web3.eth.getWork();
    // console.log(id)
    return id;
  }

  async connect() {
    const { ethereum } = window;
    if (ethereum) {
      try {
        //@ts-ignore
        const rst = await this.web3.eth.getAccounts();
        // const connectAddresses = await ethereum.request({
        //   method: "eth_requestAccounts", //fetches request acoount
        // });

        // console.log(rst)
        this.accountAddress = rst[0];

      } catch (error: any) {
        // console.log(error);
        let data = {
          msg: `${error.message}`,
          type: 'error',
          className: 'failed',
          duration: 3000,
        }
        CallBackNews.NewsSend(data);
      }
    } else {
      setTimeout(() => {
        window.open('https://metamask.io/download.html', '_blank');
      }, 500)
    }
  }

  async publicMint(): Promise<boolean> {
    try {
      const factoryContract = new this.web3.eth.Contract(
        // @ts-ignore
        this.contractAbi,
        this.contractAddress,
      );
      await factoryContract.methods
        .publicMint()
        .send({ from: this.accountAddress, value: this.ether(this.price), });
      return true;
    } catch (error) {
      return false;
    }
  }

  async getMintPassCount(): Promise<number> {
    try {
      const factoryContract = new this.web3.eth.Contract(
        // @ts-ignore
        this.contractAbi,
        this.contractAddress,
      );
      const count: number = await factoryContract.methods
        .preMintCount()
        .call();
      return count;
    } catch (error) {
      return 0;
    }
  }
  // 当前账户mint个数
  async getBalanceCount(): Promise<number> {
    try {
      const factoryContract = new this.web3.eth.Contract(
        // @ts-ignore
        this.contractAbi,
        this.contractAddress,
      );

      // console.log(this.accountAddress)
      const count: number = await factoryContract.methods
        .balanceOf(this.accountAddress)
        .call();
      // console.log(count)
      return count;
    } catch (error) {
      return 0;
    }
  }
  // 已经mint的数量
  async getTotalSupply(): Promise<number> {
    try {
      const factoryContract = new this.web3.eth.Contract(
        // @ts-ignore
        this.contractAbi,
        this.contractAddress,
      );
      const count: number = await factoryContract.methods
        .totalSupply()
        .call();
      this.totalNumber = count
      return count;
    } catch (error) {
      return 0;
    }
  }


  async mintRelatedData() {
    try {
      const factoryContract = new this.web3.eth.Contract(
        // @ts-ignore
        this.contractAbi,
        this.contractAddress,
      );
      const mintPriceVal = await factoryContract.methods.mintPrice().call();
      this.preMintCount = await factoryContract.methods.preMintCount().call(); // 每个mint限制
      this.maxCount = await factoryContract.methods.maxItems().call(); // 限制所有mint最大个数
      this.price = this.dether(mintPriceVal)
    } catch (error) {
      return 0
    }
  }

  async perMint(count: number): Promise<boolean> {
    try {
      const factoryContract = new this.web3.eth.Contract(
        // @ts-ignore
        this.contractAbi,
        this.contractAddress,
      );
      let a = false
      factoryContract.methods
        .preMint()
        .send({ from: this.accountAddress, value: this.ether(this.price * count) })
        .on('transactionHash', () => {
          // console.log(123123123)
          // this.metaMaskConfirmation = true
          a = true
        });
      return a;
    } catch (error: any) {
      return error.message;
    }
  }

  ether(eth: number): number {
    return 1000000000000000000 * eth;
  }

  dether(eth: number): number {
    return parseFloat((eth / 1000000000000000000).toFixed(2));
  }
}
