import React from "react";
import StorySwiper from "./demo";
// import StorySwiper1 from "./copy";
// import SwiperDem from "./swiper";

import './index.css'

function StoryScreen() {
  return (
    <div className="story_screen">
      <div className="story_content">
        <div className="contain">
          <h1 className="title text-center">story</h1>
          <p className="des">
            The Miya aliens have been lurking on Earth for many years. To get information on Earth's species, they made a genetic test experiment between humans and animals.The test found that the combination of the two species' serum could stimulate unlimited combat potential,so the Miya aliens began to capture more humans for sample experiments.
          </p>
          <p className="des">
            More and more mutants appear in various places in the world. At first, the side effects of mutation made them suffer, and only constant destruction and venting could calm them down. The world gradually fell into chaos.
          </p>
          <p className="des">
            To maintain the genetic stability of the human race, the surviving humans are leading the mechanical warriors to start an apocalyptic battle against aliens and mutants.
          </p>
          <StorySwiper></StorySwiper>
        </div>
      </div>
      {/* <StorySwiper1></StorySwiper1> */}
      {/* <SwiperDem></SwiperDem> */}
    </div>
  )
}

export default StoryScreen