/* eslint-disable react-hooks/exhaustive-deps */
// import { Anchor } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Popover } from 'antd';
// import LogoImage from '../assets/images/newlogo.png';
import LogoImage from '../assets/images/head-logo.png';
import NavActiveImage from '../assets/images/nav_active.png';
import popBG from '../assets/images/popbg.png'

const navTexts: string[] = ['Home', 'Mint', 'NFT', 'GamePlay', 'Characters', 'RoadMap', 'FAQ', 'Team'];

const AppbarContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  /* height: 1.28rem; */
  padding: .15rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  transition: all 0.5s linear 0s;
  background: rgba(0, 0, 0,.4);
  // backdrop-filter: saturate(180%) blur(20px);
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ImageBox = styled.div`
  /* width: 1.6rem; */
  height: .8rem;
  margin-right: .16rem;
`;

const RouteBox = styled.div`
  display: flex;
  align-items: center;
`;

const LinkBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 .2rem;
  position: relative;
  // :first-child a{
  //   color: #FFFA67;
  // }
  &.mintBtn{
    position: relative;
  }
`;

const Link = styled.a`
  display: inline-block;
  font-size: .2rem;
  position: relative;
  z-index: 2;
  color: #fff;
  padding: .05rem 0;
  :after{
    content: '';
    display: block;
    width: 220%;
    height: 170%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  :hover:after{
    background-image: url(${NavActiveImage});
  }
  :hover{
    color: #fff;
  }
`;

const PoP = styled.div`
width: 0.5rem;
line-height: .24rem;
position: absolute;
right: 0;
top: -.2rem;
transform: translateX(100%);
background: url(${popBG}) no-repeat center;
background-size: cover;
background-size: cover;
display: flex;
align-items: center;
justify-content: center;
color: #000;
`


const Appbar = (): JSX.Element => {
  const [appStyle, setAppStyle] = useState<React.CSSProperties | undefined>({
    transform: 'translate3d(0px, 0px, 0px)',
  })
  let currentTop = useRef(0).current

  useEffect(() => {
    window.addEventListener('scroll', windheaderScroll);
  }, [])

  const windheaderScroll = useCallback((event: any) => {
    const oldScrollTop = currentTop;
    const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);

    if (scrollTop <= 500) return
    if (oldScrollTop < scrollTop) {
      setAppStyle({
        transform: 'translate3d(0px, -128px, 0px)',
      })
    } else {
      setAppStyle({
        transform: 'translate3d(0px, 0px, 0px)',
      })
    }

    currentTop = scrollTop;
  }, [currentTop])
  return (
    <>
      <AppbarContainer style={appStyle}>
        <LogoContainer>
          <ImageBox>
            <img src={LogoImage} alt="Logo" height="100%" />
          </ImageBox>
          {/* <SiteName className="titleFont">
            METAGENESIS
          </SiteName> */}
        </LogoContainer>
        {/* <RouteBox>
          {
            navTexts.map((item: string, index: number) => (
              <LinkBox key={index} className={`contentBolder nav-active`}>
                <Link className="specialCursor">
                  {item}
                </Link>
              </LinkBox>
            ))
          }
        </RouteBox> */}
        <RouteBox>
          {/* <Anchor> */}
          {
            navTexts.map((item: string, index: number) => {
              if (item === 'Mint') {
                return <LinkBox key={index} className={`contentBolder nav-active mintBtn`}>
                  <Link href={`#${item}`} className="specialCursor">
                    {item}
                  </Link>
                  <PoP className="pop">
                    ING
                  </PoP>
                </LinkBox>
              } else {
                return <LinkBox key={index} className={`contentBolder nav-active`}>
                  <Link href={`#${item}`} className="specialCursor">
                    {item}
                  </Link>
                </LinkBox>
              }
            })
          }
          <Popover className="whitePaper" placement="bottom" content={'Coming Soon'} trigger="hover">
            <LinkBox className={`contentBolder nav-active`}>
              <Link className="specialCursor">
                WhitePaper
              </Link>
            </LinkBox>
          </Popover>
          <LinkBox className={`contentBolder nav-active`}>
            <Link href="https://opensea.io/collection/metagenesis-official" className="specialCursor" target={'_blank'}>
              <img src={require('../assets/images/opensea.png').default} alt="" />
            </Link>
          </LinkBox>
          {/* </Anchor> */}
        </RouteBox>

      </AppbarContainer>
    </>
  );
}

export default Appbar;
