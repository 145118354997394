/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'
import './index.css'

function Story() {
  const [moreShow, setMoreShow] = useState(false)
  return (
    <>
      <div className="story mobile_block">
        <div className="mobile_title">STORY</div>
        <div className="Copywriting">
          <p>The Miya aliens have been lurking on Earth for many years. To get information on Earth's species, they made a genetic test experiment between humans and animals.The test found that the combination of the two species' serum could stimulate unlimited combat potential,so the Miya aliens began to capture more humans for sample experiments. </p>
          {
            moreShow
              ? <div className="more">
                <p>More and more mutants appear in various places in the world. At first, the side effects of mutation made them suffer, and only constant destruction and venting could calm them down. The world gradually fell into chaos.</p>
                <p>
                  To maintain the genetic stability of the human race, the surviving humans are leading the mechanical warriors to start an apocalyptic battle against aliens and mutants.
                </p>
                <a onClick={() => { setMoreShow(false) }}>HIDE</a>
              </div>
              : <a onClick={() => { setMoreShow(true) }}>MORE</a>
          }
        </div>
        <div id='NFT' className="picWall">
          <div className="marquee-container">
            <div className="marqueee">
              <div className="horizontal-scroll">
                <div className="top imgBox">
                  {
                    new Array(6).fill('0').map((el, i) => {
                      return <div className="imgItem" key={i}>
                        <img src={require(`../../assets/nft/nft${2 * i + 1}.jpg`).default} alt="" width='100%' />
                      </div>;
                    })
                  }
                </div>
              </div>
            </div>
            <div className="marqueee marqueee2">
              <div className="horizontal-scroll">
                <div className="top imgBox">
                  {
                    new Array(6).fill('0').map((el, i) => {
                      return <div className="imgItem" key={i}>
                        <img src={require(`../../assets/nft/nft${2 * i + 1}.jpg`).default} alt="" width='100%' />
                      </div>;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="marquee-container second">
            <div className="marqueee">
              <div className="horizontal-scroll">
                <div className="bottom imgBox">
                  {
                    new Array(12).fill('0').map((el, i) => {
                      return <div className="imgItem" key={i}>
                        <img src={require(`../../assets/nft/nft${2 * i + 2}.jpg`).default} alt="" width='100%' />
                      </div>;
                    })
                  }
                </div>
              </div>
            </div>
            <div className="marqueee marqueee2">
              <div className="horizontal-scroll">
                <div className="bottom imgBox">
                  {
                    new Array(12).fill('0').map((el, i) => {
                      return <div className="imgItem" key={i}>
                        <img src={require(`../../assets/nft/nft${2 * i + 2}.jpg`).default} alt="" width='100%' />
                      </div>;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Story